<template>
  <div class="accommodation-settings">
    <!-- BUTTONS -->
    <Buttons
      :textCancel="$t('GENERAL.CANCEL')"
      v-on:cancel="cancel"
      :textSave="$t('GENERAL.SAVE_AND_NEW')"
      v-on:save="saveNew"
      :save_loading="save_loading"
      :btnSaveNew="true"
      :save_new_loading="save_new_loading"
      :textSaveNew="$t('LODGING.SAVE_EDIT')"
      v-on:save-new="saveEdit"
    />
    <!-- FORM LODGING -->
    <v-form ref="form" v-model="valid" lazy-validation>
      <!-- BASIC INFORMATION -->
      <div class="card card-custom card-stretch gutter-b w-100">
        <div class="card-body">
          <BasicInformation :basic-info.sync="basicInformation" />
        </div>
      </div>
    </v-form>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { STORE_ACCOMMODATION } from "@/core/services/store/general/accommodation.module";
import BasicInformation from "./components/BasicInformation";
import Buttons from "@/view/content/components/Buttons";
import { mapGetters } from "vuex";

const ZONE_SCHOOL = 1;

export default {
  components: {
    BasicInformation,
    Buttons,
  },
  data() {
    return {
      valid: true,
      basicInformation: null,
      save_loading: false,
      save_new_loading: false,
    };
  },
  methods: {
    /**
     * return campus page
     * @method cancel
     */
    cancel() {
      this.$router.push("/lodging");
    },

    /**
     * Save and create new ladging
     * @method saveNew
     */
    saveNew() {
      if (!this.$refs.form.validate()) {
        this.$bvToast.toast(this.$t("MESSAGE.ERROR.DESCRIPTION_NEW"), {
          title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
          variant: "danger",
          solid: true,
        });
      } else {
        this.save_loading = true;

        let cancellationPolicy;
        if (this.company.cancellationPolicy === null) {
          cancellationPolicy = 0;
        } else {
          cancellationPolicy = this.company.cancellationPolicy;
        }

        let campusId = 0;
        if (this.campus) campusId = this.campus.campusId;

        const data = {
          ...this.basicInformation,
          iban: this.company.iban,
          bic: this.company.bic,
          aba: this.company.aba,
          cancellationPolicy: cancellationPolicy,
          campusId: campusId,
        };

        data.minDistance =
          data.zoneId === ZONE_SCHOOL
            ? data.minDistanceSchool
            : data.minDistanceCity;
        data.maxDistance =
          data.zoneId === ZONE_SCHOOL
            ? data.maxDistanceSchool
            : data.maxDistanceCity;
        data.distanceTypeId =
          data.zoneId === ZONE_SCHOOL
            ? data.distanceTypeIdSchool
            : data.distanceTypeIdCity;

        this.$store
          .dispatch(STORE_ACCOMMODATION, data)
          .then(() => {
            this.save_loading = false;

            this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
              title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
              variant: "success",
              solid: true,
            });

            setTimeout(() => {
              this.$refs.form.reset();
            }, 2000);
          })
          .catch(() => {
            this.save_new_loading = false;
          });
      }
    },

    /**
     * Create new ladging
     * @method saveEdit
     */
    saveEdit() {
      if (!this.$refs.form.validate()) {
        this.$bvToast.toast(this.$t("MESSAGE.ERROR.DESCRIPTION_NEW"), {
          title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
          variant: "danger",
          solid: true,
        });
      } else {
        this.save_new_loading = true;

        let cancellationPolicy;
        if (this.company.cancellationPolicy === null) {
          cancellationPolicy = 0;
        } else {
          cancellationPolicy = this.company.cancellationPolicy;
        }

        const data = {
          ...this.basicInformation,
          iban: this.company.iban,
          bic: this.company.bic,
          aba: this.company.aba,
          cancellationPolicy: cancellationPolicy,
          campusId: this.campus.campusId ? this.campus.campusId : null,
        };

        data.minDistance =
          data.zoneId === ZONE_SCHOOL
            ? data.minDistanceSchool
            : data.minDistanceCity;
        data.maxDistance =
          data.zoneId === ZONE_SCHOOL
            ? data.maxDistanceSchool
            : data.maxDistanceCity;
        data.distanceTypeId =
          data.zoneId === ZONE_SCHOOL
            ? data.distanceTypeIdSchool
            : data.distanceTypeIdCity;

        this.$store
          .dispatch(STORE_ACCOMMODATION, data)
          .then(({ accommodation }) => {
            this.save_new_loading = false;

            this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
              title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
              variant: "success",
              solid: true,
            });

            const { accommodationId } = accommodation;

            setTimeout(() => {
              this.$router.push(`/lodging/edit/${accommodationId}`);
            }, 2000);
          })
          .catch(() => {
            this.save_new_loading = false;
          });
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("LODGING.PAGE"),
        route: "/lodging",
      },
      { title: this.$t("GENERAL.NEW") },
    ]);
  },
  created() {},
  computed: {
    ...mapGetters(["AccommodationTypes", "Currencies", "company", "campus"]),
  },
};
</script>
