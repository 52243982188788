import i18n from "@/core/plugins/vue-i18n";
const { LODGING, GENERAL } = i18n.messages.en;

export const unitList = [
  { value: 1, text: "KM" },
  { value: 2, text: "MI" },
];

export const unitList2 = [
  { value: 1, text: "m" },
  { value: 2, text: "ft" },
];

export const allowedList = [
  { value: 1, text: LODGING.GENDER.OPT1 },
  { value: 2, text: LODGING.GENDER.OPT2 },
  { value: 3, text: LODGING.GENDER.OPT3 },
];

export const optionsDuration = [
  { value: 2, text: GENERAL.DAYS },
  { value: 3, text: GENERAL.WEEKS },
  { value: 4, text: GENERAL.MONTHS },
];

export const listOptions5 = [
  { value: 0, text: "0" },
  { value: 1, text: "1" },
  { value: 2, text: "2" },
  { value: 3, text: "3" },
  { value: 4, text: "4" },
  { value: 5, text: "5" },
];

export const listOptions10 = [
  { value: 1, text: "1" },
  { value: 2, text: "2" },
  { value: 3, text: "3" },
  { value: 4, text: "4" },
  { value: 5, text: "5" },
  { value: 6, text: "6" },
  { value: 7, text: "7" },
  { value: 8, text: "8" },
  { value: 9, text: "9" },
  { value: 10, text: "10" },
];

export const listOptions11 = [
  { value: 0, text: "0" },
  { value: 1, text: "1" },
  { value: 2, text: "2" },
  { value: 3, text: "3" },
  { value: 4, text: "4" },
  { value: 5, text: "5" },
  { value: 6, text: "6" },
  { value: 7, text: "7" },
  { value: 8, text: "8" },
  { value: 9, text: "9" },
  { value: 10, text: "10" },
];

export const listMesure = [
  { value: 1, text: "m.2" },
  { value: 2, text: "ft.2" },
];

export const listPersonType = [
  { value: 1, text: LODGING.PERSON.PERSON_OPT1 },
  { value: 2, text: LODGING.PERSON.PERSON_OPT2 },
  { value: 3, text: LODGING.PERSON.PERSON_OPT3 },
  { value: 4, text: LODGING.PERSON.PERSON_OPT4 },
  { value: 5, text: LODGING.PERSON.PERSON_OPT5 },
];

export const listPersonSex = [
  { value: 1, text: LODGING.SEX.MALE },
  { value: 2, text: LODGING.SEX.FEMALE },
  { value: 3, text: LODGING.SEX.OTHER },
];

export const listPersonReligion = [
  { value: 1, text: LODGING.RELIGION_LIST.RELIGION_OPT1 },
  { value: 2, text: LODGING.RELIGION_LIST.RELIGION_OPT2 },
  { value: 3, text: LODGING.RELIGION_LIST.RELIGION_OPT3 },
  { value: 4, text: LODGING.RELIGION_LIST.RELIGION_OPT4 },
  { value: 5, text: LODGING.RELIGION_LIST.RELIGION_OPT5 },
  { value: 6, text: LODGING.RELIGION_LIST.RELIGION_OPT6 },
  { value: 10, text: LODGING.OTHER },
  { value: 11, text: LODGING.RELIGION_LIST.RELIGION_OPT7 },
];

export const listPet = [
  { value: 1, text: LODGING.PETS.PET_OPT1 },
  { value: 2, text: LODGING.PETS.PET_OPT2 },
  { value: 3, text: LODGING.PETS.PET_OPT3 },
  { value: 4, text: LODGING.PETS.PET_OPT4 },
  { value: 5, text: LODGING.PETS.PET_OPT5 },
  { value: 6, text: LODGING.PETS.PET_OPT6 },
  { value: 7, text: LODGING.PETS.PET_OPT7 },
  { value: 8, text: LODGING.PETS.PET_OPT8 },
  { value: 9, text: LODGING.OTHER },
];

export const listPet2 = [
  { value: 1, text: LODGING.PETS.PET_OPT1 },
  { value: 2, text: LODGING.PETS.PET_OPT2 },
  { value: 3, text: LODGING.PETS.PET_OPT3 },
  { value: 4, text: LODGING.PETS.PET_OPT4 },
  { value: 5, text: LODGING.PETS.PET_OPT5 },
  { value: 6, text: LODGING.PETS.PET_OPT6 },
  { value: 7, text: LODGING.PETS.PET_OPT7 },
  { value: 8, text: LODGING.PETS.PET_OPT8 },
];
