<template>
  <div>
    <b-row>
      <!-- TITLE -->
      <b-col lg="12" md="12" sm="12" cols="12">
        <h5>
          <strong>
            {{ $t("LODGING.TITLE") }}
          </strong>
        </h5>
      </b-col>

      <!-- NAME -->
      <b-col lg="6" md="6" sm="12" cols="12">
        <v-text-field
          v-model="info.name"
          :label="$t('LODGING.NAME')"
          :rules="validations.required"
          required
          outlined
          dense
        />
      </b-col>

      <!-- Category -->
      <b-col lg="6" md="6" sm="12" cols="12">
        <v-select
          v-if="edit"
          @change="$emit('change', info.accommodationTypeId)"
          v-model="info.accommodationTypeId"
          :rules="validations.required"
          :items="filterAccommodationTypes"
          :label="$t('LODGING.CATEGORY')"
          item-text="name"
          item-value="accommodationTypeId"
          required
          dense
          outlined
        />
        <v-select
          v-else
          v-model="info.accommodationTypeId"
          :rules="validations.required"
          :items="filterAccommodationTypes"
          :label="$t('LODGING.CATEGORY')"
          item-text="name"
          item-value="accommodationTypeId"
          required
          dense
          outlined
        />
      </b-col>

      <!-- ADDRESS -->
      <b-col lg="6" md="6" sm="12" cols="12" v-if="edit && !itsSchool">
        <VueGooglePlaces
          v-model="info.address"
          :api-key="key"
          types="address"
          @placechanged="getAddressData"
          class="without-placeholder"
        >
          <v-text-field
            v-model="info.address"
            :label="$t('LODGING.ADDRESS')"
            :rules="[
              validations.address(
                info.address,
                info.addressComponent,
                edit,
                accommodation.address
              ),
            ]"
            required
            outlined
            dense
          />
        </VueGooglePlaces>
      </b-col>

      <!-- CITY -->
      <b-col lg="6" md="6" sm="12" cols="12" v-if="edit">
        <VueGooglePlaces
          v-model="info.cityName"
          :api-key="key"
          types="(cities)"
          @placechanged="getCityData"
          class="without-placeholder"
        >
          <v-text-field
            v-model="info.cityName"
            :label="$t('LODGING.CITY')"
            :rules="[
              validations.city(
                info.cityName,
                info.cityComponent,
                edit,
                accommodation.google_place
              ),
            ]"
            required
            outlined
            dense
          />
        </VueGooglePlaces>
      </b-col>

      <!-- CURRENCY -->
      <b-col lg="6" md="6" sm="12" cols="12" v-if="itsSchool && edit">
        <v-autocomplete
          v-model="info.currencyId"
          :rules="validations.required"
          :items="Currencies"
          :label="$t('LODGING.CURRENCY')"
          item-text="newCode"
          item-value="currencyId"
          required
          dense
          outlined
        />
      </b-col>

      <!-- MINIMUM AGE -->
      <b-col lg="4" md="4" sm="12" cols="12">
        <v-text-field
          v-model="info.minAge"
          :label="$t('LODGING.MINIMUM_AGE')"
          :rules="validations.minAge"
          required
          outlined
          dense
        >
          <template v-slot:append>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-help-circle-outline</v-icon>
              </template>
              {{ $t("LODGING.HELP_MINIMUM_AGE") }}
            </v-tooltip>
          </template>
        </v-text-field>
      </b-col>

      <!-- MAXIMUM AGE -->
      <b-col lg="4" md="4" sm="12" cols="12" v-if="edit">
        <v-text-field
          v-model="info.maxAge"
          :label="$t('LODGING.MAXIMUM_AGE')"
          :rules="[validations.biggerNumber(info.minAge, info.maxAge)]"
          outlined
          dense
        />
      </b-col>

      <!--ALLOWED GENRES -->
      <b-col lg="4" md="4" sm="12" cols="12">
        <v-select
          v-model="info.allowedGenres"
          :rules="validations.required"
          :items="optionAllowed"
          :label="$t('LODGING.ALLOWED_GENRES')"
          item-text="text"
          item-value="value"
          required
          dense
          outlined
        />
      </b-col>

      <!-- CURRENCY -->
      <b-col lg="4" md="4" sm="12" cols="12" v-if="itsSchool && !edit">
        <v-autocomplete
          v-model="info.currencyId"
          :rules="validations.required"
          :items="Currencies"
          :label="$t('LODGING.CURRENCY')"
          item-text="newCode"
          item-value="currencyId"
          required
          dense
          outlined
        />
      </b-col>

      <!-- CURRENCY -->
      <b-col lg="4" md="4" sm="12" cols="12" v-if="!itsSchool">
        <v-autocomplete
          v-model="info.currencyId"
          :rules="validations.required"
          :items="Currencies"
          :label="$t('LODGING.CURRENCY')"
          item-text="newCode"
          item-value="currencyId"
          required
          dense
          outlined
        />
      </b-col>

      <!-- ADDRESS -->
      <b-col lg="6" md="6" sm="12" cols="12" v-if="!edit && !itsSchool">
        <VueGooglePlaces
          v-model="info.address"
          :api-key="key"
          types="address"
          @placechanged="getAddressData"
          class="without-placeholder"
        >
          <v-text-field
            v-model="info.address"
            :label="$t('LODGING.ADDRESS')"
            :rules="[validations.address(info.address, info.addressComponent)]"
            required
            outlined
            dense
          >
            <template v-slot:append>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                </template>
                {{ $t("LODGING.HELP_ADDRESS") }}
              </v-tooltip>
            </template>
          </v-text-field>
        </VueGooglePlaces>
      </b-col>

      <!-- CITY -->
      <b-col lg="6" md="6" sm="12" cols="12" v-if="!edit">
        <VueGooglePlaces
          v-model="info.cityName"
          :api-key="key"
          types="(cities)"
          @placechanged="getCityData"
          class="without-placeholder"
        >
          <v-text-field
            v-model="info.cityName"
            :label="$t('LODGING.CITY')"
            :rules="[validations.city(info.cityName, info.cityComponent)]"
            required
            outlined
            dense
          />
        </VueGooglePlaces>
      </b-col>

      <!-- TITLE3 -->
      <b-col lg="12" md="12" sm="12" cols="12" v-if="itsSchool || edit">
        <h5>
          {{ $t("LODGING.TITLE3") }}
        </h5>
        <i v-if="itsSchool">
          {{ $t("LODGING.TITLE21") }}
        </i>
        <i v-else>
          {{ $t("LODGING.TITLE22") }}
        </i>
      </b-col>

      <!-- ZONE -->
      <b-col lg="12" md="12" sm="12" cols="12" v-if="itsSchool">
        <v-radio-group v-model="info.zoneId" row :rules="validations.required">
          <v-radio :label="$t('LODGING.ZONE_OPT1')" :value="1" />
          <v-radio :label="$t('LODGING.ZONE_OPT2')" :value="2" />
          <v-radio :label="$t('LODGING.ZONE_OPT3')" :value="3" />
          <v-radio :label="$t('LODGING.ADDRESS')" :value="4" />
        </v-radio-group>
      </b-col>

      <b-col lg="12" md="12" sm="12" cols="12" v-if="edit && !itsSchool">
        <h5 class="text-muted" v-if="itsHomeStayAndHomeTuition">
          <strong>
            {{ $t("LODGING.INFO20") }}
          </strong>
        </h5>
        <v-radio-group v-model="info.zoneId" row>
          <v-radio
            :label="$t('LODGING.ZONE_OPT1')"
            :value="1"
            v-if="!itsHomeStayAndHomeTuition"
          />
          <v-radio :label="$t('LODGING.ZONE_OPT2')" :value="2" />
          <v-radio :label="$t('LODGING.ZONE_OPT3')" :value="3" />
        </v-radio-group>
      </b-col>

      <!-- MINIMUM -->
      <b-col lg="3" md="3" sm="12" cols="12" v-if="itsZoneDistance">
        <v-text-field
          v-model="info.minDistanceSchool"
          v-if="info.zoneId === ZONE_SCHOOL"
          type="number"
          :label="$t('LODGING.MIN_DISTANCE')"
          :rules="[
            validations.minorNumber(
              info.minDistanceSchool,
              info.maxDistanceSchool,
              false
            ),
          ]"
          outlined
          dense
        />
        <v-text-field
          v-model="info.minDistanceCity"
          v-if="info.zoneId === ZONE_CITY"
          type="number"
          :label="$t('LODGING.MIN_DISTANCE')"
          :rules="[
            validations.minorNumber(
              info.minDistanceCity,
              info.maxDistanceCity,
              false
            ),
          ]"
          outlined
          dense
        />
      </b-col>

      <!-- MAXIMUM -->
      <b-col lg="3" md="3" sm="12" cols="12" v-if="itsZoneDistance">
        <v-text-field
          v-model="info.maxDistanceSchool"
          v-if="info.zoneId === ZONE_SCHOOL"
          :rules="[
            validations.biggerNumberRequired(
              info.minDistanceSchool,
              info.maxDistanceSchool,
              false
            ),
          ]"
          type="number"
          :label="$t('LODGING.MAX_DISTANCE')"
          class="required"
          required
          outlined
          dense
        />
        <v-text-field
          v-model="info.maxDistanceCity"
          v-if="info.zoneId === ZONE_CITY"
          :rules="[
            validations.biggerNumberRequired(
              info.minDistanceCity,
              info.maxDistanceCity,
              false
            ),
          ]"
          type="number"
          :label="$t('LODGING.MAX_DISTANCE')"
          class="required"
          required
          outlined
          dense
        />
      </b-col>

      <!-- UNIT -->
      <b-col lg="3" md="3" sm="12" cols="12" v-if="itsZoneDistance">
        <v-select
          v-model="info.distanceTypeIdSchool"
          v-if="info.zoneId === ZONE_SCHOOL"
          :items="unitOptions"
          :label="$t('LODGING.UNIT')"
          item-text="text"
          item-value="value"
          class="required"
          required
          dense
          outlined
        />
        <v-select
          v-model="info.distanceTypeIdCity"
          v-if="info.zoneId === ZONE_CITY"
          :items="unitOptions"
          :label="$t('LODGING.UNIT')"
          item-text="text"
          item-value="value"
          class="required"
          required
          dense
          outlined
        />
      </b-col>

      <!-- DISTRIC -->
      <b-col
        lg="6"
        md="6"
        sm="12"
        cols="12"
        v-if="info.zoneId === ZONE_DISTRIC"
      >
        <VueGooglePlaces
          v-model="info.district"
          :api-key="key"
          types="address"
          @placechanged="getDistricData"
          class="without-placeholder"
        >
          <v-text-field
            v-model="info.district"
            class="required"
            :label="$t('LODGING.DISTRIC')"
            :rules="[
              validations.address(
                info.district,
                info.districtComponent,
                edit,
                accommodation.district
              ),
            ]"
            required
            outlined
            dense
          />
        </VueGooglePlaces>
      </b-col>

      <!-- ADDRESS -->
      <b-col lg="6" md="6" sm="12" cols="12" v-if="info.zoneId === 4">
        <VueGooglePlaces
          v-model="info.address"
          :api-key="key"
          types="address"
          @placechanged="getAddressData"
          class="without-placeholder"
        >
          <v-text-field
            v-model="info.address"
            :label="$t('LODGING.ADDRESS')"
            :rules="[
              validations.address(
                info.address,
                info.addressComponent,
                edit,
                accommodation.address
              ),
            ]"
            class="required"
            required
            outlined
            dense
          >
            <template v-slot:append>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                </template>
                {{ $t("LODGING.HELP_ADDRESS") }}
              </v-tooltip>
            </template>
          </v-text-field>
        </VueGooglePlaces>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Rules from "@/core/services/utils/validations";
import { unitList, allowedList } from "./../util/list";
import { VueGooglePlaces } from "vue-google-places";

const SCHOOL = 1;
const LODGING = 2;
const HOMESTAY = 3;
const HOMETUITION = 4;

const TYPE_SHARED_APARTMENT = 4;
const TYPE_HOME_STAY = 1;

export default {
  components: {
    VueGooglePlaces,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      key: process.env.VUE_APP_PLACE_KEY,
      form: {
        name: null,
        accommodationTypeId: null,
        address: null,
        addressComponent: null,
        cityName: null,
        googlePlaceId: null,
        cityComponent: null,
        zoneId: null,
        minDistanceSchool: null,
        maxDistanceSchool: null,
        minDistanceCity: null,
        maxDistanceCity: null,
        distanceTypeIdSchool: 1,
        distanceTypeIdCity: 1,
        district: null,
        districtComponent: null,
        minAge: null,
        maxAge: null,
        allowedGenres: 3,
        currencyId: null,
      },
      //OPTIONS SELECT
      unitOptions: unitList,
      optionAllowed: allowedList,
      // VALIDATIONS
      validations: {
        ...Rules,
      },
      ZONE_SCHOOL: 1,
      ZONE_CITY: 2,
      ZONE_DISTRIC: 3,
    };
  },
  methods: {
    /**
     * get info google place for addressComponent
     * @method getAddressData
     * @returns 'addressComponent' object whit data
     */
    getAddressData(addressData) {
      this.form.addressComponent = addressData;
    },

    /**
     * get info google place for cityComponent
     * @method getCityData
     * @returns 'cityComponent' object whit data
     */
    getCityData(cityData) {
      this.form.googlePlaceId = cityData.place_id;
      this.form.cityComponent = cityData;
    },
    /**
     * get info google place for districComponent
     * @method getDistricData
     * @returns 'districComponent' object whit data
     */
    getDistricData(districData) {
      this.form.districtComponent = districData;
    },

    fetchData() {
      if (
        this.edit &&
        this.accommodation &&
        Object.keys(this.accommodation).length > 0
      ) {
        const { zoneId } = this.accommodation;
        this.form.name = this.accommodation.name;
        this.form.accommodationTypeId = this.accommodation.accommodationTypeId;
        this.form.address = this.accommodation.address;
        this.form.cityName = this.accommodation.google_place.translation;
        this.form.zoneId = zoneId;
        this.form.minAge = this.accommodation.minAge;
        this.form.maxAge = this.accommodation.maxAge;
        this.form.allowedGenres = this.accommodation.allowedGenres;
        this.form.currencyId = this.accommodation.currencyId;
        this.form.googlePlaceId = this.accommodation.googlePlaceId;
        this.form.district = this.accommodation.district;

        if (zoneId === this.ZONE_SCHOOL) {
          this.form.minDistanceSchool = this.accommodation.minDistance;
          this.form.maxDistanceSchool = this.accommodation.maxDistance;
          this.form.distanceTypeIdSchool = this.accommodation.distanceTypeId;
        } else if (zoneId === this.ZONE_CITY) {
          this.form.minDistanceCity = this.accommodation.minDistance;
          this.form.maxDistanceCity = this.accommodation.maxDistance;
          this.form.distanceTypeIdCity = this.accommodation.distanceTypeId;
        }
      }
    },
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    ...mapGetters([
      "company",
      "AccommodationTypes",
      "Currencies",
      "accommodation",
    ]),
    info() {
      return this.form;
    },
    showAddress() {
      const { companyTypeId } = this.company;
      if (companyTypeId === 1 || companyTypeId === 3) {
        return false;
      }
      return true;
    },
    itsZoneDistance() {
      return this.info.zoneId === 1 || this.info.zoneId === 2;
    },
    itsSchool() {
      const { companyTypeId } = this.company;
      return companyTypeId === SCHOOL;
    },
    itsLodging() {
      const { companyTypeId } = this.company;
      return companyTypeId === LODGING;
    },
    itsHomeStayAndHomeTuition() {
      const { companyTypeId } = this.company;
      return companyTypeId === HOMESTAY || companyTypeId === HOMETUITION;
    },
    filterAccommodationTypes() {
      const { companyTypeId } = this.company;
      if (companyTypeId === LODGING) {
        return this.AccommodationTypes?.filter(item => {
          return (
            item.accommodationTypeId !== TYPE_SHARED_APARTMENT &&
            item.accommodationTypeId !== TYPE_HOME_STAY
          );
        });
      } else if (companyTypeId === SCHOOL) {
        return this.AccommodationTypes;
      } else {
        return this.AccommodationTypes?.filter(item => {
          return (
            item.accommodationTypeId === TYPE_SHARED_APARTMENT ||
            item.accommodationTypeId === TYPE_HOME_STAY
          );
        });
      }
    },
  },
  watch: {
    form: {
      handler: function() {
        const data = {
          ...this.form,
        };
        this.$emit("update:basic-info", data);
      },
      deep: true,
    },
    $route: "fetchData",
    accommodation: "fetchData",
  },
};
</script>
